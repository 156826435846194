import { render, staticRenderFns } from "./graph-legend.vue?vue&type=template&id=5425fb68&scoped=true"
import script from "./graph-legend.vue?vue&type=script&setup=true&lang=ts"
export * from "./graph-legend.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./graph-legend.vue?vue&type=style&index=0&id=5425fb68&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5425fb68",
  null
  
)

export default component.exports