var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.BNavbar,{staticClass:"m-0 p-0",attrs:{"toggle-breakpoint":"lg","toggleable":"lg"}},[_c('div',{staticClass:"header py-4 my-header"},[_c('div',{staticClass:"container-fluid",staticStyle:{"max-width":"1360px"}},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"header-brand mr-0 mt-0",attrs:{"to":{ name: 'network-dashboard' }}},[_c('img',{staticClass:"header-brand-img mr-0",attrs:{"src":_setup.brandLogoSource,"alt":_vm.brandLogo ? _vm.brandLogo.alt : undefined}})]),_c('h2',{staticClass:"brand-title mb-0"},[_vm._v(_vm._s(_vm.brandName))])],1),_c('div',{staticClass:"d-none d-lg-flex"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"nav-item pr-0",staticStyle:{"cursor":"default"}},[(_setup.store.networkContexts.size > 1 && !_setup.store.isLoading)?_c(_setup.BNavItemDropdown,{staticClass:"text-default nav-link px-0",staticStyle:{"width":"137px"},attrs:{"toggle-class":"text-default"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_setup.store.getNetworkContextName())+" ")]},proxy:true}],null,false,989865730)},_vm._l((Array.from(
                      _setup.store.networkContexts.keys()
                    )),function(network){return _c(_setup.BDropdownItem,{key:network,on:{"click":function($event){return _setup.navigateToNetwork(network)}}},[_vm._v(" "+_vm._s(_setup.store.getNetworkContextName(network))+" ")])}),1):_c('div',{staticClass:"text-gray",staticStyle:{"width":"137px"}},[_vm._v(" "+_vm._s(_setup.store.getNetworkContextName())+" ")]),_c('a',{staticClass:"btn btn-sm btn-outline-primary",attrs:{"href":"https://github.com/xdbchain","target":"_blank","rel":"noopener"}},[_c(_setup.Github),_vm._v(" Github")],1),_c('a',{staticClass:"btn btn-sm btn-outline-primary ml-2",attrs:{"href":`mailto:${_setup.store.appConfig.brandEmail}`,"target":"_blank"}},[_c(_setup.BIconEnvelope),_vm._v(" Mail")],1)],1)])]),_c(_setup.BNavbarToggle,{staticClass:"my-navbar-toggle",attrs:{"target":"nav_collapse"}})],1)])])]),_c(_setup.BCollapse,{staticClass:"header collapse d-lg-flex p-0",attrs:{"is-nav":"","id":"nav_collapse"}},[_c('div',{staticClass:"container-fluid collapser",staticStyle:{"max-width":"1360px"}},[_c('div',{staticClass:"row align-items-center"},[(!_setup.store.isLoading)?_c(_setup.BNavItemDropdown,{staticClass:"ml-0 pl-0 mt-3 d-lg-none",attrs:{"variant":"primary","toggle-class":"gray"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_setup.store.getNetworkContextName())+" ")]},proxy:true}],null,false,989865730)},_vm._l((Array.from(
              _setup.store.networkContexts.values()
            )),function(networkContext){return _c(_setup.BDropdownItem,{key:networkContext.networkId,on:{"click":function($event){return _setup.navigateToNetwork(networkContext.networkId)}}},[_vm._v(" "+_vm._s(networkContext.name)+" ")])}),1):_vm._e(),_c('div',{staticClass:"col-lg order-lg-first"},[_c('ul',{staticClass:"nav nav-tabs border-0 flex-column flex-lg-row"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_setup.homeActiveClass,attrs:{"active-class":"active","exact-active-class":"active","exact":"","to":{
                  name: 'network-dashboard',
                  query: {
                    view: _vm.$route.query.view,
                    network: _vm.$route.query.network,
                    at: _vm.$route.query.at,
                  },
                }}},[_c(_setup.BIconHouse,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" Home ")],1)],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","exact-active-class":"active","to":{
                  name: 'nodes',
                  query: {
                    view: _vm.$route.query.view,
                    network: _vm.$route.query.network,
                    at: _vm.$route.query.at,
                  },
                },"exact":""}},[_c(_setup.BIconBullseye,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" Nodes ")],1)],1),(_vm.includeOrganizations)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
                  name: 'organizations',
                  query: {
                    view: _vm.$route.query.view,
                    network: _vm.$route.query.network,
                    at: _vm.$route.query.at,
                  },
                },"exact":""}},[_c(_setup.BIconBuilding,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" Organizations ")],1)],1):_vm._e(),(
                _vm.includeNotify &&
                !_setup.store.isLoading &&
                !_setup.store.fetchingDataFailed &&
                _setup.store.networkId === 'public' &&
                !_setup.store.isSimulation
              )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
                  name: 'subscribe',
                  query: {
                    view: _vm.$route.query.view,
                    network: _vm.$route.query.network,
                    at: _vm.$route.query.at,
                  },
                }}},[_c(_setup.BIconBell,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" Notify ")],1)],1):_vm._e(),(_vm.blogUrl)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":_vm.blogUrl,"rel":"noopener"}},[_c(_setup.BIconNewspaper,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" Blog ")],1)]):_vm._e(),(_vm.apiDocUrl)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":_vm.apiDocUrl,"rel":"noopener"}},[_c(_setup.BIconCode,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" API ")],1)]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
                  name: 'faq',
                  query: {
                    view: _vm.$route.query.view,
                    network: _vm.$route.query.network,
                    at: _vm.$route.query.at,
                  },
                }}},[_c(_setup.BIconQuestionCircle,{staticClass:"mr-1",attrs:{"scale":"0.9"}}),_vm._v(" FAQ ")],1)],1)])]),_c('div',{staticClass:"col-lg-3 ml-auto"},[_c('form',{staticClass:"input-icon my-3 my-lg-0"},[(!_setup.store.isLoading && !_setup.store.fetchingDataFailed)?_c(_setup.Search):_vm._e()],1)])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }