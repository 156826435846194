var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page full",attrs:{"id":"app"}},[_c('div',{staticClass:"flex-fill"},[_c(_setup.Navbar,{attrs:{"brand-tagline":_setup.store.appConfig.brandTagline,"brand-name":_setup.store.appConfig.brandName,"brand-logo":{
        alt: _setup.store.appConfig.brandLogoAlt,
        src: _setup.store.appConfig.brandLogoSrc,
      },"api-doc-url":_setup.store.appConfig.apiDocUrl,"blog-url":_setup.store.appConfig.blogUrl,"include-notify":_setup.store.networkContext.enableNotify}}),_c('div',{staticClass:"container-fluid h-100 mt-0 mt-md-2",staticStyle:{"max-width":"1360px"}},[_c(_setup.BAlert,{attrs:{"show":_setup.showError,"variant":"danger"}},[_vm._v(_vm._s(_setup.errorMessage))]),_c(_setup.BAlert,{attrs:{"show":['fbas', 'fbas2'].includes(_setup.store.networkContext.networkId),"variant":"info"}},[_vm._v("Learn more about the demo networks here! >")]),(_setup.store.isLoading)?_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"loader"})]):_c('div',[(!_setup.store.isLoading && !_setup.store.fetchingDataFailed)?_c('router-view',{attrs:{"isLoading":_setup.store.isLoading}}):_vm._e(),_c(_setup.CustomNetwork)],1)],1)],1),_c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container-fluid",staticStyle:{"max-width":"1360px"}},[_c('div',{staticClass:"d-flex justify-content-between mx-4"},[_c('div',{},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
              name: 'terms-and-conditions',
              query: {
                view: _vm.$route.query.view,
                network: _vm.$route.query.network,
                at: _vm.$route.query.at,
              },
            },"exact":""}},[_vm._v(" Terms and Conditions ")]),_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
              name: 'privacy',
              query: {
                view: _vm.$route.query.view,
                network: _vm.$route.query.network,
                at: _vm.$route.query.at,
              },
            },"exact":""}},[_vm._v(" Privacy Policy ")])],1),_c('div',{staticClass:"nav-item d-lg-flex pr-0"},[_c('div',{staticClass:"d-flex"},[_c('a',{staticClass:"btn btn-sm bt btn-secondary gray",attrs:{"href":"https://github.com/xdbchain","target":"_blank","rel":"noopener"}},[_c(_setup.Github),_vm._v(" Github")],1),_c('a',{staticClass:"btn btn-sm bt btn-secondary gray ml-2",attrs:{"href":`mailto:${_setup.store.appConfig.brandEmail}`,"rel":"noopener","target":"_blank"}},[_c(_setup.BIconEnvelope),_vm._v(" Mail")],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }