import { render, staticRenderFns } from "./github.vue?vue&type=template&id=af98fe0a&scoped=true"
import script from "./github.vue?vue&type=script&setup=true&lang=ts"
export * from "./github.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af98fe0a",
  null
  
)

export default component.exports