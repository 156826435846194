var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.dimmerClass,staticStyle:{"height":"100%"}},[_c('div',{staticClass:"loader"}),_c('div',{staticClass:"dimmer-content svg-wrapper h-100"},[_c('svg',{ref:"graphSvg",staticClass:"graph",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"100%"}},[_c('g',{ref:"grid"},[(!_setup.isLoading && _setup.viewGraph)?_c('g',[_vm._l((_setup.viewGraph.regularEdges.filter(
              (mEdge) =>
                (!mEdge.isFailing || _setup.optionShowFailingEdges) &&
                (mEdge.isPartOfTransitiveQuorumSet ||
                  !_vm.optionTransitiveQuorumSetOnly)
            )),function(edge){return _c('path',{key:edge.key,staticClass:"edge",class:_setup.getEdgeClassObject(edge),attrs:{"d":_setup.getEdgePath(edge)}})}),_vm._l((_setup.viewGraph.stronglyConnectedEdges.filter(
              (mEdge) =>
                (!mEdge.isFailing || _setup.optionShowFailingEdges) &&
                (mEdge.isPartOfTransitiveQuorumSet ||
                  !_vm.optionTransitiveQuorumSetOnly)
            )),function(edge){return _c('path',{key:edge.key,staticClass:"edge",class:_setup.getEdgeClassObject(edge),attrs:{"d":_setup.getEdgePath(edge)}})}),(
              _setup.selectedVertices &&
              _setup.selectedVertices.length > 0 &&
              _setup.optionHighlightTrustingNodes
            )?_c('g',_vm._l((_setup.viewGraph.trustingEdges.filter(
                (mEdge) =>
                  (!mEdge.isFailing || _setup.optionShowFailingEdges) &&
                  (mEdge.isPartOfTransitiveQuorumSet ||
                    !_vm.optionTransitiveQuorumSetOnly)
              )),function(edge){return _c('path',{key:edge.key + edge.key,staticClass:"edge incoming",attrs:{"d":_setup.getEdgePath(edge)}})}),0):_vm._e(),(
              _setup.selectedVertices &&
              _setup.selectedVertices.length > 0 &&
              _setup.optionHighlightTrustedNodes
            )?_c('g',_vm._l((_setup.viewGraph.trustedEdges.filter(
                (mEdge) =>
                  (!mEdge.isFailing || _setup.optionShowFailingEdges) &&
                  (mEdge.isPartOfTransitiveQuorumSet ||
                    !_vm.optionTransitiveQuorumSetOnly)
              )),function(edge){return _c('path',{key:edge.key + edge.key,staticClass:"edge outgoing",attrs:{"d":_setup.getEdgePath(edge)}})}),0):_vm._e(),_c(_setup.GraphStronglyConnectedComponent,{attrs:{"greatest":true,"vertex-coordinates":_setup.viewGraph.transitiveQuorumSetCoordinates}}),(!_vm.optionTransitiveQuorumSetOnly)?_c('g',_vm._l((_setup.viewGraph.stronglyConnectedComponentCoordinates),function(sccCoordinates,index){return _c(_setup.GraphStronglyConnectedComponent,{key:index,attrs:{"vertex-coordinates":sccCoordinates}})}),1):_vm._e(),_vm._l((Array.from(
              _setup.viewGraph.viewVertices.values()
            ).filter(
              (mVertex) =>
                mVertex.isPartOfTransitiveQuorumSet ||
                !_vm.optionTransitiveQuorumSetOnly
            )),function(vertex){return _c('g',{key:vertex.key,staticClass:"vertex",staticStyle:{"cursor":"pointer"},attrs:{"transform":_setup.getVertexTransform(vertex)},on:{"click":function($event){return _setup.vertexSelected(vertex)}}},[_c('circle',{class:_setup.getVertexClassObject(vertex),attrs:{"r":5}},[_c('title',[_vm._v(_vm._s(vertex.label))])]),_c('g',[_c('rect',{class:{
                  'rect-selected': vertex.selected,
                  rect: !vertex.selected,
                },staticStyle:{"fill":"white","opacity":"0.7","text-transform":"lowercase"},attrs:{"width":_setup.getVertexTextRectWidthPx(vertex),"height":"13px","y":"10","x":_setup.getVertexTextRectX(vertex),"rx":"2"}}),_c('text',{class:_setup.getVertexTextClass(vertex),attrs:{"y":"5","dy":"1.3em","text-anchor":"middle","font-size":"12px"}},[_vm._v(" "+_vm._s(_setup.truncate(vertex.label, 10))+" "),_c('title',[_vm._v(_vm._s(vertex.label))])])])])})],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }